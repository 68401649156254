.loginsignup {
    width: 100%;
    background: #fce3fe;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
}

.loginsignup-container {
    width: 90%;
    max-width: 500px;
    background: white;
    padding: 40px 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.loginsignup-container h1 {
    margin: 0 0 20px;
    font-size: 28px;
    color: #333;
    text-align: center;
}

.loginsignup-fields {
    margin-top: 30px;
}

.loginsignup-fields input {
    height: 45px;
    width: 90%;
    padding: 0 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    color: #333;
    font-size: 16px;
    transition: border 0.3s ease;
}

.loginsignup-fields input:focus {
    border-color: #ff4141;
}

.loginsignup-container button {
    width: 100%;
    height: 50px;
    color: white;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
}

.loginsignup-container button:hover {
    background: #e60000;
}

.loginsignup-login {
    margin-top: 20px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.loginsignup-login span {
    color: #ff4141;
    font-weight: 600;
    cursor: pointer;
}

.loginsignup-agree {
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.loginsignup-agree input {
    cursor: pointer;
}

/* Media queries for smaller screens */
@media screen and (max-width: 768px) {
    .loginsignup{
        width: 90%;
    }
    .loginsignup-container {
        padding: 20px 15px;
    }

    .loginsignup-container h1 {
        font-size: 24px;
    }

    .loginsignup-fields input {
        height: 40px;
        font-size: 14px;
    }

    .loginsignup-container button {
        height: 45px;
        font-size: 18px;
    }

    .loginsignup-login {
        font-size: 14px;
    }

    .loginsignup-agree {
        font-size: 12px;
    }
}
