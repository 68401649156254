.footerWrap {
    background-color: #f2f2f2;
    padding: 48px;
    margin-top: 25px;
  }
  
  .footer {
    background-color: #f2f2f2;
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerB {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .FH {
    font-size: 24px;
    font-weight: 900;
  }
  
  .FH1 {
    font-size: 16px;
    font-weight: 500;
  }
  .first2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 24px;
  }
  .second2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 24px;
    color: #0A0A0A;
  }
  .second24 {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      margin-top: 24px;
      color: #474747;
    }
    .underLine{
      text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
    line-height: 12px;
    margin-top: 24px;
    color: #0A0A0A;
    text-underline-offset: 5px;
    }
  .inputH {
    margin-top: 24px;
  }
  .input {
    /* width: 373px; */
    /* height: 47px; */
    
    background-color: #ebebeb;
    border: none;
  }
  .HiP {
    padding: 17px;
    background-color: #0a0a0a;
  }

  .footer_social_handles_container {
    background-color: whitesmoke;
    display: flex;
    justify-content: flex-start;
    padding: 0;
}

/* General styling for social handles */
.footer_social_handle {
    display: grid;
}

.footer_social_handle_insta,
.footer_social_handle_facebook {
    display: flex;
    align-items: center;
    margin-right: 5px;
    text-decoration: none;
    color: black;
}

.footer_social_handle_text {
    font-size: 18px;
    margin-top: 5px;
}

  @media screen and (max-width: 768px) {
    .footer {
      display: grid;
    }
    .footerB {
      display: grid;
    }
  }
  