.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%; /* Adjusted width for smaller screens */
  text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #45a049;
  }
  
  @media screen and (max-width: 768px) {
    .modal-content {
      max-width: 90%; /* Adjusted max-width for tablets */
    }
  }
  
  @media screen and (max-width: 480px) {
    .modal-content {
      max-width: 90%; /* Adjusted max-width for mobile devices */
      padding: 10px; /* Reduced padding for smaller screens */
    }
  }