.hero-search{
    margin-top: 15px;
    margin-left: 30%;
    margin-bottom: 10px;
}

.product-category-banner{
    display: flex;
    margin: 30px auto;
    width: 82%;
}
.productcategory-indexsort{
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}
.productCategory-sort{
    padding: 10px 5apx;
    border-radius: 30px;
    border: 1px solid #888;
}
.productcategory-indexsort p span{
    font-weight: 600;
}
.productCategory-items{
    margin-top: 30px;
    display: flex; /* Set popular_item to flex container */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
    gap: 30px;
}
.productCategory-loadmore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
    width: 233px;
    height: 49px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Full viewport height */
  }

@media screen and (max-width: 768px) {
    .hero-search input[type="text"] {
        width: 150px;
    }
    
    .product-category-banner {
        flex-direction: column; /* Change to column layout for smaller screens */
        margin: 20px auto; /* Adjust margin for smaller screens */
        width: 100%; /* Make width full for smaller screens */
    }

    .productCategory-sort{
        padding: 2px 5px;
        border-radius: 30px;
        border: 1px solid #888;
        font-size: 10px;
    }

    .productCategory-sort img{
        width: 10px;
    }

    .productcategory-indexsort {
        margin: 0px 20px; /* Adjust margin for smaller screens */
    }

    .productcategory-indexsort span {
        font-size: 10px;
        font-weight: 600;
    }

    .productcategory-indexsort p{
        font-size: 10px;
    }

    .productCategory-items {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        gap: 20px; /* Adjust gap between items */
    }

    .productCategory-loadmore {
        margin: 50px auto; /* Adjust margin for smaller screens */
        width: 180px; /* Adjust width for smaller screens */
        height: 40px; /* Adjust height for smaller screens */
        font-size: 16px; /* Adjust font size for smaller screens */
    }
}
