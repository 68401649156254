.cartitems {
    margin: 90px 10%; /* Adjust margin for responsiveness */
}

.cartitems hr {
    height: 3px;
    background: #e2e2e2;
    border: 0;
}

.cartitems-format-main {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr; /* Adjust grid layout for responsiveness */
    align-items: center;
    gap: 20px; /* Adjust gap for responsiveness */
    padding: 15px 0px;
    color: #454545;
    font-size: 15px;
    font-weight: 400;
}

/* Adjust font size and weight for .cartitems-format */
.cartitems-format {
    font-size: 15px;
    font-weight: 500;
}

/* Adjust height for .carticon-product-icon */
.carticon-product-icon {
    height: 62px;
}

/* Adjust width and margin for .cartitems-remove-icon */
.cartitems-remove-icon {
    width: 12px;
    margin: 0px 25px;
    cursor: pointer;
}

/* Adjust width and height for .cartitems-quantity */
.cartitems-quantity-con{
    justify-content: space-between;
}
.cartitems-quantity {
    margin: 5px;
    width: 58px;
    height: 40px;
    background: #ffff;
}

/* Adjust margin for .cartitems-down */
.cartitems-down {
    display: flex;
    margin: 90px 0px;
}

/* Adjust margin-right for .cartitems-total */
.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}

/* Adjust padding for .cartitems-total-item */
.cartitems-total-item {
    display: flex;
    justify-content: space-between;
    padding: 13px 0px;
}

/* Adjust button width for .cartitems-total button */
.cartitems-total button {
    width: 230px;
    height: 48px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

/* Adjust font size and weight for .cartitems-promocode */
.cartitems-promocode {
    flex: 1;
    font-size: 13px;
    font-weight: 400;
}

/* Adjust margin-top for .cartitems-promobox */
.cartitems-promobox {
    width: 450px;
    margin-top: 13px;
    padding-left: 15px;
    height: 48px;
    background: #eaeaea;
}

/* Adjust input width for .cartitems-promobox input */
.cartitems-promobox input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 14px;
    width: 300px;
    height: 45px;
}

/* Adjust button width for .cartitems-promobox button */
.cartitems-promobox button {
    width: 150px;
    height: 48px;
    font-size: 14px;
    background: black;
    color: white;
    cursor: pointer;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .cartitems {
        margin: 50px 5%; /* Adjust margin for smaller screens */
    }

    .cartitems-total-item {
        display: grid;
        justify-content: space-between;
        padding: 13px 0px;
    }

    .cartitems-format-main {
        grid-template-columns: 1fr 2fr 1fr; /* Adjust grid layout for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
    }
}
