.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: auto; /* Remove fixed height */
}

.popular h1 {
    color: #171717;
    font-size: 35px;
    font-weight: 600;
}

.popular hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}

.popular_item {
    margin-top: 30px;
    display: flex; /* Set popular_item to flex container */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
    gap: 30px;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Full viewport height */
  }
  
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .popular h1{
        font-size: 25px;
    }
    .popular_item {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        gap: 20px; /* Adjust gap between items */
    }
}
