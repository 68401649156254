.item {
  width: 230px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.item p {
    margin: 6px 0px;
}

.item_text{
  height: 40px;
  padding: 3px;
}

.item-price-con {
    display: flex;
    gap: 20px;
}

.item-prices {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
}

.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}

.add-to-cart-button {
    display: block;
    width: 100%;
    padding: 2px;
    margin-top: 10px;
    border: none;
    background-color: #06488e;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
    background-color: #0056b3;
}

/*Skeleton Wrapper styling*/
.skeleton-wrapper {
    width: 100%;
    height: auto;
    background-color: #f0f0f0; /* Fallback color */
    background-image: linear-gradient(90deg, #f0f0f0 0px, #e0e0e0 40px, #f0f0f0 80px);
    background-size: 200% auto;
    animation: loading 1.5s infinite linear;
  }
  
  .skeleton-img {
    width: 100%;
    height: 200px; /* Adjust height as needed */
  }
  
  .skeleton-text {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0; /* Fallback color */
    background-image: linear-gradient(90deg, #f0f0f0 0px, #e0e0e0 40px, #f0f0f0 80px);
    background-size: 200% auto;
    animation: loading 1.5s infinite linear;
  }
  
  @keyframes loading {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .item {
        width: 80%; /* Make each item take full width */
        margin-bottom: 20px; /* Add margin between items */
    }
}
