/* Reset padding for social handles container */
.social_handles_container {
    background-color: whitesmoke;
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

/* General styling for social handles */
.social_handle {
    display: flex;
}

.social_handle_insta,
.social_handle_facebook {
    display: flex;
    align-items: center;
    margin-right: 20px;
    text-decoration: none;
    color: black;
}

.social_handle_text {
    font-size: 18px;
    margin-top: 5px;
}

/* Navbar styling */
.navbar {
    display: flex;
    justify-content: space-between; /* Adjust for better positioning */
    align-items: center; /* Center vertically */
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
    
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-logo p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #171717;
    font-size: 30px;
    font-weight: 400;
}

.nav-logo .img_logo{
    width: 40px;
    height: auto;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: #626262;
    font-size: 20px;
    font-weight: 500;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}

.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background-color: #ff4141;
}

.nav-login-cart {
    display: flex;
    align-items: center;
    gap: 45px;
}

.nav-login-cart button {
    width: 87px;
    height: 38px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
}

.nav-login-cart button:active {
    background: #f3f3f3;
}

.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}

/* Media queries */
@media(max-width: 1024px) {
    .navbar {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to start */
    }

   .nav-logo{
    margin-top: 10px;
    }
    .nav-logo p {
        font-size: 24px; /* Adjust font size */
        margin-left: 40px;
        margin-bottom: 10px;
        font-weight: 600;
        font-style: oblique;
    }

    .nav-logo .img_logo{
        display: none;
    }

   /* Toggle button for smaller screens */
.toggle-menu {
    display: none; /* Hide toggle menu by default */
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    cursor: pointer;
    
}

.toggle-line {
    width: 100%;
    height: 3px;
    background-color: #000;
}

/* Active state for toggle button */
.toggle-menu.active .line1 {
    transform: rotate(45deg) translate(5px, 5px);
}

.toggle-menu.active .line2 {
    opacity: 0;
}

.toggle-menu.active .line3 {
    transform: rotate(-45deg) translate(7px, -7px);
}


    .nav-menu {
        display: none; /* Hide menu by default */
        position: absolute;
        top: 60px; /* Adjust top position */
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        z-index: 999;
    }

    .nav-menu.active {
        display: flex; /* Show menu when active */
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to start */
        gap: 20px; /* Adjust gap */
    }

    .nav-login-cart {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .nav-login-cart img {
        width: 20px;
    }

    .nav-login-cart button {
        width: 40px;
        height: auto;
        font-size: 10px;
        padding: 5px
    }

    .nav-cart-count {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
        margin-left: -13px;
        border-radius: 11px;
        font-size: 10px;
    }
}
  
@media (max-width: 768px) {

    .social_handles_container {
        display: none;
    }

    .navbar {
        padding: 0px 10px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .nav-logo{
        align-items: center;
        gap: 30px;
    }

    .nav-logo p{
        font-size: 20px;
    }

    .toggle-menu {
        display: flex;
        margin-bottom: 5px;
    }

}
