.breadcrum {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 500;
    margin: 50px 150px;
    text-transform: capitalize;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .breadcrum {
        font-size: 10px;
        gap: 5px;
        margin: 15px 5px; /* Adjust margin for smaller screens */
    }
}
