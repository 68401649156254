.hero {
    min-height: 80vh;
    background-color: antiquewhite;
    display: flex;
    flex-wrap: wrap;
}

.hero-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 0 20px;
    line-height: 1.1;
}

.hero-left h2 {
    color: #090909;
    font-size: 42px;
    font-weight: 600;
    text-transform: uppercase;
}

.hero-left p {
    color: #171717;
    font-size: 45px;
    font-weight: 700;
}

.hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hero-hand-icon{
    font-size: 30px;
}
.hero-hand-icon p{
    font-size: 30px;
}

.hero-hand-icon img {
    width: 80px;
}

.hero-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 200px;
    height: 50px;
    border-radius: 25px;
    margin-top: 20px;
    background: #ff4141;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.hero-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-right img {
    width: 100%;
    height: auto;
}

.hero-search {
    display: flex;
  }

  .hero-search input[type="text"] {
    width: 300px; /* Adjust the width as needed */
    padding: 8px; /* Adjust padding as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px; /* Adjust margin between input and button */
  }

  .hero-search button {
    background-color: #ff4141; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    border-radius: 4px;
    padding: 8px 12px; /* Adjust padding as needed */
    cursor: pointer;
  }

  .filtered-products{
    display: grid;
    gap: 5px;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .hero{
        padding: 16px;
        min-height: auto;
        display: grid;
        justify-content: center;
    }
    .hero-left h2 {
        justify-content: center;
        font-size: 25px;
        font-weight: 600;
    }
    .hero-left p {
        font-size: 20px;
    }

    .hero-hand-icon img {
        width: 60px;
    }

    .hero-latest-btn {
        height: 40px;
        border-radius: 20px;
        margin-top: 15px;
        font-size: 16px;
    }

    .hero-search {
        display: flex;
      }

    .hero-search input[type="text"] {
        width: 150px;
        align-content: center;
    }

  .filtered-products{
    grid-template-columns: 1fr;
    justify-items: center;
    }

}
