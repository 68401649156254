.place-order {
    display: flex;
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center items horizontally */
    gap: 50px;
    margin-top: 100px;
    margin: 10px;
    padding: 0px 20px; /* Add padding to the sides */
}

.place-order-left {
    width: 100%;
    max-width: 500px; /* Limit the width on larger screens */
}

.place-order-left .title {
    font-size: 24px; /* Adjust font size */
    font-weight: 600;
    margin-bottom: 20px; /* Reduce margin */
}

.place-order-left input {
    margin-bottom: 15px;
    width: 60%;
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    outline-color: tomato;
}

.place-order-left .multi-fields {
    display: flex;
    flex-direction: column; /* Stack fields vertically */
    gap: 10px;
}

.place-order-right {
    width: 100%;
    max-width: 500px; /* Limit the width on larger screens */
}

.place-order .cartitems-total button {
    margin-top: 30px;
}

.cartitems-total-item-delivery {
    display: block;
}

/* Media queries for responsiveness */

@media (min-width: 768px) {
    .place-order {
        flex-direction: row; /* Arrange items side by side on larger screens */
        justify-content: space-between; /* Add space between items */
        padding: 0px; /* Remove horizontal padding */
    }

    .place-order-left {
        width: 50%; /* Take up half of the available width */
        max-width: none; /* Remove the maximum width */
        margin-right: 20px; /* Add space between left and right sections */
    }

    .place-order-right {
        width: 50%; /* Take up half of the available width */
        max-width: none; /* Remove the maximum width */
    }

    .place-order-left .title {
        font-size: 30px; /* Increase font size */
    }

    .place-order-left input,
    .place-order-left .multi-fields {
        width: 100%; /* Ensure inputs and multi-fields take full width */
    }
}
