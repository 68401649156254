.descriptionbox {
    margin: 100px 10%; /* Adjust margin for responsiveness */
}

.descriptionbox-navigator {
    display: flex;
}

.descriptionbox-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    width: 151px;
    height: 50px;
    border: 1px solid #d0d0d0;
}

.descriptionbox-nav-box.fade {
    background: #fbfbfb;
    color: #555;
}

.descriptionbox-description {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 40px;
    padding-bottom: 50px;
}

.descriptionbox-description p{
    font-size: 30px;
    font-weight: 800;
    justify-content: center;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .descriptionbox {
        margin: 50px 5%; /* Adjust margin for smaller screens */
    }

    .descriptionbox-nav-box {
        font-size: 12px; /* Adjust font size for smaller screens */
        width: 120px; /* Adjust width for smaller screens */
        height: 40px; /* Adjust height for smaller screens */
    }

    .descriptionbox-description {
        padding: 20px; /* Adjust padding for smaller screens */
        padding-bottom: 30px; /* Adjust padding-bottom for smaller screens */
    }
}
