.productdisplay{
    display: flex;
    margin: 0px 120px;
}
.productdisplay-left{
    display: flex;
    gap: 15px;
}
.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.productdisplay-img-list img{
    height: 120px;
}
.productdisplay-main-img{
    width: 286px;
    height: 400px;
}
.productdisplay-right{
    margin: 0px 50px;
    display: flex;
    flex-direction: column;
}
.productdisplay-right h1{
    color: #3d3d3d;
    font-size: 25px;
    font-weight: 600;
}
.productdisplay-right-prices-con{
    display: flex;
    margin: 20px 0px;
    gap: 20px;
    font-size: 24px;
    font-weight: 500;
}
.productdisplay-right-price{
    color: #818181;
}
.productdisplay-right-size h1{
    margin-top: 40px;
    color: #656565;
    font-size: 16px;
    font-weight: 500;
}
.productdisplay-right-sizes{
    display: flex;
    margin: 20px 0px;
    gap: 16px;
}

.productdisplay-right-description{
    font-size: 20px;
    font-weight: 500;
}

.productdisplay-right-description span{
    font-weight: bold;
}

.productdisplay-right-sizes div{
    padding: 15px 20px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    cursor: pointer;
}
.productdisplay-right button{
    padding: 10px;
    width: 100px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    background: #ff4141;
    margin-top: 15px;
    border-radius: 30px;
    cursor: pointer;
}
.productdisplay-right-category{
    margin-top: 10px;
}
.productdisplay-right-category span{
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .productdisplay {
        flex-direction: column; /* Change to column layout for smaller screens */
        margin: 0px 20px; /* Adjust margin for smaller screens */
    }

    .productdisplay-left {
        gap: 10px; /* Adjust gap for smaller screens */
    }

    .productdisplay-img-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px; /* Adjust gap for smaller screens */
    }

    .productdisplay-img-list img {
        height: 100px; /* Decrease image height for smaller screens */
    }

    .productdisplay-main-img {
        width: 100%; /* Make main image width full for smaller screens */
        height: auto; /* Auto height for smaller screens */
    }

    .productdisplay-right {
        margin: 20px 0px; /* Adjust margin for smaller screens */
    }

    .productdisplay-right h1 {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .productdisplay-right-prices-con {
        margin: 10px 0px; /* Adjust margin for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .productdisplay-right-size h1 {
        margin-top: 20px; /* Adjust margin for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .productdisplay-right-sizes {
        margin: 10px 0px; /* Adjust margin for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
    }

    .productdisplay-right-sizes div {
        padding: 10px 15px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .productdisplay-right button {
        padding: 15px; /* Adjust padding for smaller screens */
        width: 30%; /* Make button width full for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
        margin-bottom: 20px; /* Adjust margin for smaller screens */
    }

    .productdisplay-right-category {
        margin-top: 10px; /* Adjust margin for smaller screens */
    }
}
