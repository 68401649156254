.contact-us-container {
    padding: 30px;
    max-width: 700px;
    margin: 0 auto;
    background: #ccc;
  }
  
  .address {
    margin-bottom: 10px;
  }
  
  .contact-number {
    margin-bottom: 20px;
  }
  
  .contact-form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .map-container {
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio (height: 9/16 = 0.5625) */
  }
  
  .map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  