.marquee {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
}
.marqueeH {
  background-color: #08660b;
}
.marqueeI {
  color: #ff4141;
  padding-left: 10px;
  padding-right: 10px;
}
